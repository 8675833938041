<template>
  <div>
    <div class="box">
      <img
        class="bg-img-pc"
        src="../../assets/images/2ndanniv/bgImg_p.jpg"
        alt=""
      />
      <img
        class="bg-img-mb"
        src="../../assets/images/2ndanniv/bgImg_m.jpg"
        alt=""
      />
      <div class="erweima-box">
        <a href="https://apps.apple.com/app/id1597706553">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/d_app_1.png"
            alt=""
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.tripellet.app&hl=en-TW"
        >
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/d_app_2.png"
            alt=""
          />
        </a>
      </div>
      <div class="snsIconBox">
        <a href="https://www.facebook.com/travelcontents/">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon1_p.png"
            alt=""
          />
        </a>
        <a href="https://www.instagram.com/travelcontents.tw/">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon2_p.png"
            alt=""
          />
        </a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon3_p.png"
            alt=""
          />
        </a>
      </div>
      <!-- mb -->
      <div class="snsIconBox-mb">
        <a href="https://www.facebook.com/travelcontents/">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon1_p.png"
            alt=""
          />
        </a>
        <a href="https://www.instagram.com/travelcontents.tw/">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon2_p.png"
            alt=""
          />
        </a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q">
          <img
            class="btn2"
            src="../../assets/images/2ndanniv/snsIcon3_p.png"
            alt=""
          />
        </a>
      </div>
      <!-- 主办单位 -->
      <!-- <div class="zbdw-mb">
        <img src="../../assets/images/2ndanniv/zbdw.png" alt="">
      </div> -->
      <!-- 奖励 -->
      <div class="jianlibox-mb">
        <a
          href="https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/tc"
          ><img src="../../assets/images/2ndanniv/jianli_1.png" alt=""
        /></a>
        <a
          href="https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/tc"
          ><img src="../../assets/images/2ndanniv/jianli_2.png" alt=""
        /></a>
      </div>
      <div class="jianlibox-pc">
        <a
          href="https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/tc"
          ><img src="../../assets/images/2ndanniv/jianliPc_1.png" alt=""
        /></a>
        <a
          href="https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/tc"
          ><img src="../../assets/images/2ndanniv/jianliPc_2.png" alt=""
        /></a>
      </div>
      <!-- 小logo -->
      <!-- <div class="appIconBox">
        <a href="http://onelink.to/xyx76x"><img src="../../assets/images/2ndanniv/APP_icon.png" alt=""></a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }

  .erweima-box {
    width: 17.1%;
    position: absolute;
    bottom: 2.8%;
    left: 43.6%;
    // transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .btn1 {
      width: 18%;
      height: 18%;
    }
    a {
      display: inline-block;
      width: 48%;
      height: 100%;
    }
  }

  .snsIconBox {
    width: 18.4%;
    position: absolute;
    bottom: 9.5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    a {
      width: 22.6%;
      height: 22.6%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.jianlibox-pc {
  display: inline-block;
  display: flex;
  width: 34%;
  position: absolute;
  top: 22.4%;
  left: 50%;
  transform: translateX(-50%);
  a {
    position: absolute;
    width: 52.6%;
    img {
      width: 100%;
    }
  }
  & > a:nth-child(1) {
    left: 0;
  }

  & > a:nth-child(2) {
    right: 0;
  }
}

.bg-img-mb,
.snsIconBox-mb,
.zbdw-mb,
.jianlibox-mb,
.appIconBox {
  display: none;
}

@media (max-width: 750px) {
  .box {
    .bg-img-pc,
    .snsIconBox,
    .jianlibox-pc {
      display: none;
    }
    .erweima-box {
      width: 85%;
      position: absolute;
      bottom: 2.7%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      .btn1 {
        width: 18%;
        height: 18%;
      }
      a {
        display: inline-block;
        width: 47%;
        height: 100%;
      }
    }
  }
  .bg-img-mb {
    display: inline-block;
  }
  .snsIconBox-mb {
    display: inline-block;
    width: 62.8%;
    position: absolute;
    bottom: 8.4%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    a {
      width: 21.6%;
      height: 21.6%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .zbdw-mb {
    display: inline-block;
    width: 54%;
    position: absolute;
    bottom: 8.2%;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: auto;
    }
  }

  .jianlibox-mb {
    display: flex;
    width: 112%;
    position: absolute;
    top: 17.8%;
    left: 50%;
    transform: translateX(-50%);
    a {
      position: absolute;
      width: 57.5%;
      img {
        width: 100%;
      }
    }
    & > a:nth-child(1) {
      left: 0;
    }

    & > a:nth-child(2) {
      right: 0;
    }
  }

  .appIconBox {
    display: inline-block;
    width: 10%;
    position: absolute;
    top: 34.8%;
    right: 9%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
}
</style>
